<template>
  <div class="animated fadeIn">
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item :key="index" v-for="(item, index) in type_list" @click="changeType(item.index, item.code)"
        :active="code == item.index">{{ item.label }}</b-nav-item>
    </b-nav>
    <b-card v-if="code == 0">
      <b-row>
        <b-col sm="6">
          <b-card title="PDF Татах">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <b-form @submit.stop.prevent="searchRegisterNumber">
              <b-form-input v-model="register_number" placeholder="Регистрийн дугаар"></b-form-input>
              <b-button class="mt-3" type="submit" variant="primary">Татах</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>


      <b-row>
        <b-col>
          <ZMS :datas="data" :register="register_number" :cus_info="cus_info" :file_url="file_url"></ZMS>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else>
      <b-row>
        <b-col sm="6">
          <b-card title="Хамтран зээлдэгчийн PDF Татах">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <b-form @submit.stop.prevent="searchCoRegisterNumber">
              <b-form-input v-model="register_number" placeholder="Регистрийн дугаар"></b-form-input>
              <b-button class="mt-3" type="submit" variant="primary">Татах</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>


      <b-row>
        <b-col>
          <ZMS :datas="data" :register="register_number" :cus_info="cus_info" :file_url="file_url"></ZMS>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import ZMS from "./ZMS.vue";

export default {
  name: "Cib.getPdf",
  components: {
    ZMS,
  },
  data: function () {
    return {
      save_session: "",
      isLoading: false,
      isLoadingSession: false,
      session: "",
      register_number: "",
      data: [],
      cus_info: null,
      file_url: null,
      type_list: [
        {
          index: 0,
          code: 0,
          label: "Өөрийн",
        },
        {
          index: 1,
          code: 1,
          label: "Хамтран зээлдэгчийн",
        },
      ],
      code: 0
    };
  },

  methods: {
    searchRegisterNumber: function () {
      if (this.$data.register_number != "") {
        this.$data.data = [];
        this.$data.cus_info = null;
        this.isLoading = true;
        this.$data.pdf_url = "";
        this.$http
          .post(
            this.$config.API_URL + "CibWebService/inquiry",
            {
              data: JSON.stringify({
                token: this.$store.getters.token,
                email: this.$store.getters.email,
                registerNumber: this.$data.register_number.toUpperCase(),
              }),
            },
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              console.log(response)
              this.isLoading = false

              if (response.body.responseResultType == 'SUCCESS') {
                let loan_data = JSON.parse(response.body.loanData)
                console.log(loan_data)
                if (loan_data.success) {
                  this.$data.data = loan_data.inquiry;
                  this.$data.cus_info = loan_data.info;
                  this.$data.file_url = loan_data.file_url
                  this.showToast("Амжилттай", loan_data.message, "success");
                } else {
                  this.showToast("Анхаар", loan_data.message, "warning");
                }
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Алдаа", _ms[i].failureMessage, "danger");
                }
              }

              // if (response.status == 200) {
              //   if (response.body.success) {
              //     this.showToast("Амжилттай", response.body.message, "success");
              //     if (response.body.pdfUrl != "")
              //       window.open(response.body.pdfUrl, "_blank");
              //   } else {
              //     this.showToast(
              //       "Алдаа",
              //       response.body.message != ""
              //         ? response.body.message
              //         : "Алдаа гарсан байна",
              //       "danger"
              //     );
              //   }
              // } else {
              //   this.showToast("Алдаа", "Татах үед алдаа гарлаа", "danger");
              // }
            },
            (response) => {
              // //console.log(response);
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
      } else {
        this.showToast("Анхаар", "Session хоосон байна", "warning");
      }
    },

    searchCoRegisterNumber: function () {
      if (this.$data.register_number != "") {
        this.$data.data = [];
        this.$data.cus_info = null;
        this.isLoading = true;
        this.$data.pdf_url = "";
        this.$http
          .post(
            this.$config.API_URL + "CibWebService/co_inquiry",
            {
              data: JSON.stringify({
                token: this.$store.getters.token,
                email: this.$store.getters.email,
                registerNumber: this.$data.register_number.toUpperCase(),
              }),
            },
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              console.log(response)
              this.isLoading = false

              if (response.body.responseResultType == 'SUCCESS') {
                let loan_data = JSON.parse(response.body.loanData)
                console.log(loan_data)
                if (loan_data.success) {
                  this.$data.data = loan_data.inquiry;
                  this.$data.cus_info = loan_data.info;
                  this.$data.file_url = loan_data.file_url
                  this.showToast("Амжилттай", loan_data.message, "success");
                } else {
                  this.showToast("Анхаар", loan_data.message, "warning");
                }
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Алдаа", _ms[i].failureMessage, "danger");
                }
              }

              // if (response.status == 200) {
              //   if (response.body.success) {
              //     this.showToast("Амжилттай", response.body.message, "success");
              //     if (response.body.pdfUrl != "")
              //       window.open(response.body.pdfUrl, "_blank");
              //   } else {
              //     this.showToast(
              //       "Алдаа",
              //       response.body.message != ""
              //         ? response.body.message
              //         : "Алдаа гарсан байна",
              //       "danger"
              //     );
              //   }
              // } else {
              //   this.showToast("Алдаа", "Татах үед алдаа гарлаа", "danger");
              // }
            },
            (response) => {
              // //console.log(response);
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
      } else {
        this.showToast("Анхаар", "Session хоосон байна", "warning");
      }
    },

    changeType(index, code) {
      this.$data.code = code;

    }
  },
};
</script>